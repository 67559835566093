import {MainBlogList} from 'components/Blog/MainBlogList';
import { useEffect } from 'react';
import type { GetStaticProps } from 'next';
import { useDispatch } from 'react-redux';

import normalizeGoods from '../shared/lib/normalizers/normalizeGoods';
import normalizeCollections from '../shared/lib/normalizers/normalizeCollections';
import { setPopularCategories } from 'src/store/categories';

import type { Banner } from '../src/models/IBanner';
import type { IGoodCard } from '../entities/products/model/IGood';
import type { ICategory } from '../entities/categories/model/ICategory';
import type { ICollection } from '../src/models/ICollection';
import type { ISticker } from 'src/models/ISticker';

import Banners from '../components/MainPage/Banners';
import { PopularCategories } from 'app/categories';
import Collections from '../components/MainPage/Collections';
import { CartModal, ProductsCarousel, ProductsList } from '../widgets/products';

import { api } from '../global/api';

import styles from 'global/styles/Home.module.scss';

interface Props {
  banners: {
    slider: Array<Banner>;
    miniature: Array<Banner>;
  };
  hits: IGoodCard[];
  newGoods: IGoodCard[];
  popularCategories: ICategory[];
  collections: ICollection[];
  collectionName: string;
  hitsStickers: ISticker[] | null;
  newStickers: ISticker[] | null;
}

export default function Home({
  banners,
  hits,
  newGoods,
  popularCategories,
  collections,
  collectionName,
  hitsStickers,
  newStickers,
}: Props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPopularCategories(popularCategories));
  }, [popularCategories]);


  return (
    <div className={styles.homePage}>
      <div className={styles.mobileSearch}>
        <CartModal />
      </div>
      <section className={styles.bannersBlock}>
        <Banners forSlider={banners.slider} forMiniature={banners.miniature} />
      </section>
      {popularCategories && popularCategories.length > 0 && (
        <section className={styles.popularCategoriesBlock}>
          <div className="container">
            <h2 className={styles.sectionTitle}>Популярные категории </h2>
          </div>
          <PopularCategories categories={popularCategories} />
        </section>
      )}
      {hits && hits.length > 0 && (
        <section className={styles.hitsBlock}>
          <div className="container">
            <h2 className={styles.sectionTitle}>Хиты продаж</h2>
          </div>
          <ProductsCarousel goods={hits} stickers={hitsStickers} />
        </section>
      )}
      {collections && collections.length > 0 && (
        <section className={styles.popularCategoriesBlock}>
          <div className="container">
            <h2 className={styles.sectionTitle}>{collectionName}</h2>
          </div>
          <Collections collections={collections} />
        </section>
      )}
      {newGoods && newGoods.length > 0 && (
        <section>
          <div className={`${styles.newGoodsBlock} container`}>
            <h2 className={styles.sectionTitle}>Новые предложения </h2>
            <ProductsList goods={newGoods} stickers={newStickers} newProposals={true} />
          </div>
        </section>
      )}
      <section className={styles.blogBlock}>
        <div className="container">
          <h2 className={styles.sectionTitle}>Блог</h2>
        </div>
        <MainBlogList />
      </section>
    </div>
  );
}

export const getStaticProps: GetStaticProps<Props> = async () => {
  const banners = {
    slider: [],
    miniature: [],
  };
  let hits = null;
  let hitsStickers = null;
  let newStickers = null;
  let newGoods = null;
  let popularCategories = null;
  let collections = null;
  let collectionName = '';

  try {
    const [
      sliderBannersRes,
      miniatureBannersRes,
      hitsRes,
      newGoodsRes,
      popularCategoriesRes,
      collectionsRes,
      collectionNameRes,
    ] = await Promise.all([
      api.getBanners({ type: 'SLIDER' }),
      api.getBanners({ type: 'MINIATURE', limit: 3 }),
      api.getHits(),
      api.getNewGoods(),
      api.getPopularCategories(),
      api.getCollections(),
      api.getCollectionName(),
    ]);

    banners.slider = sliderBannersRes.data.data;
    banners.miniature = miniatureBannersRes.data.data;
    hits = normalizeGoods(hitsRes.data.data);
    newGoods = normalizeGoods(newGoodsRes.data.data);
    popularCategories = popularCategoriesRes.data.data;
    collections = normalizeCollections(collectionsRes.data.data);
    collectionName = collectionNameRes.data.data.name;
    hitsStickers = hitsRes.data.meta?.stickers || null;
    newStickers = newGoodsRes.data.meta?.stickers || null;
  } catch (error) {
    console.error(error);
  }

  return {
    props: {
      banners,
      hits,
      newGoods,
      popularCategories,
      collections,
      collectionName,
      hitsStickers,
      newStickers,
    },
    revalidate: 600,
  };
};
