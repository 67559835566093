import React, { FC } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Banner } from 'src/models/IBanner';

import styles from './styles.module.scss';

type Props = {
  slides: Array<Banner>;
};

type SubBannerContent = {
  title?: string;
  description?: string;
};

const SubBannerContent: FC<SubBannerContent> = (props) => {
  if (!props.title && !props.description) return null;

  return (
    <div className={styles.subBannerContent}>
      <div className={styles.subBannerTitle} dangerouslySetInnerHTML={{ __html: props.title }}></div>
      <div className={styles.subBannerDecription}>{props.description}</div>
    </div>
  );
};

const SubBanner: FC<Props> = ({ slides }) => {
  return (
    <>
      <div className={styles.subBanners}>
        {slides.map((slide) => (
          <Link key={slide.id} href={slide.url} className={styles.subBanner}>
            {slide.desktop_image && (
              <Image src={slide.desktop_image.variants.original.url} alt="Banner" fill style={{ objectFit: 'fill' }} />
            )}
            <SubBannerContent title={slide.title} description={slide.description} />
          </Link>
        ))}
      </div>
      <div className={styles.subBannersMobile}>
        <Swiper slidesPerView={'auto'} spaceBetween={8} speed={600} className={styles.swiper}>
          {slides.map((slide) => (
            <SwiperSlide key={slide.id}>
              <Link href={slide.url} className={styles.subBanner}>
                {(slide.mobile_image || slide.desktop_image) && (
                  <Image
                    src={slide.mobile_image?.variants?.original.url || slide.desktop_image.variants.original.url}
                    alt="Banner"
                    fill
                    style={{ objectFit: 'fill' }}
                  />
                )}
                <SubBannerContent title={slide.title} description={slide.description} />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default SubBanner;
